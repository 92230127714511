import { AiiiError } from '@aiii/aiii-types';
import { environment } from 'src/environments/environment';
import { HCPList } from './typings';

/**
 * 取得HCP列表
 * @param {string} site 站台
 * @param {string} LINEUserId LINE User ID
 * @param {boolean} isAssigned 選擇已指派或未指派
 * @param {string} nameSearch 姓名搜尋
 * @param {string} institutionSearch 院所搜尋
 * @return {Promise<any>}
 */
export const getHcpListFromMedSales = async (site: string, LINEUserId: string, isAssigned: boolean, nameSearch: string, institutionSearch: string, limit: number, page: number): Promise<HCPList> => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('x-api-key', environment.cloudrun.medAPIKey);

  const raw = JSON.stringify({
    site,
    LINEUserId,
    isAssigned,
    nameSearch,
    institutionSearch,
    limit,
    page,
  });

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const result = await fetch(`${environment.cloudrun.gskApi}/hcp/list-for-med-sales`, requestOptions);
  if (!result.ok) {
    throw new AiiiError(400, '取得HCP列表失敗');
  }
  return result.json() as Promise<HCPList>;
};
