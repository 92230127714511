import { environmentBase } from './environment-base';

export const environment = {
  ...environmentBase,
  production: false,
  firebase: {
    apiKey: 'AIzaSyDU1KGqlcJ87gfuUH2aerjfhJglkbUpn1c',
    authDomain: 'aiii-developer.firebaseapp.com',
    databaseURL: 'https://aiii-developer.firebaseio.com',
    projectId: 'aiii-developer',
    storageBucket: 'aiii-developer.appspot.com',
    messagingSenderId: '700749202278',
  },
  firebaseConfig: {
    us: {
      apiKey: 'AIzaSyDU1KGqlcJ87gfuUH2aerjfhJglkbUpn1c',
      authDomain: 'aiii-developer.firebaseapp.com',
      databaseURL: 'https://aiii-developer.firebaseio.com',
      projectId: 'aiii-developer',
      storageBucket: 'aiii-developer.appspot.com',
      messagingSenderId: '700749202278',
      region: 'us-central',
    },
    taiwan: {
      apiKey: 'AIzaSyC5K17qCPu1R-m-VKSvH7AG8LSIiDH5HpQ',
      authDomain: 'aiii-developer-tw.firebaseapp.com',
      databaseURL: 'https://aiii-developer-tw.firebaseio.com',
      projectId: 'aiii-developer-tw',
      storageBucket: 'aiii-developer-tw.appspot.com',
      messagingSenderId: '296524928200',
      region: 'asia-east1',
    },
    testAllan: {
      apiKey: 'AIzaSyATSDeV6-Xrv2JWz0qad4QP1tCX36VmHyU',
      authDomain: 'aiii-testallan.firebaseapp.com',
      projectId: 'aiii-testallan',
      storageBucket: 'aiii-testallan.appspot.com',
      messagingSenderId: '648546485642',
      appId: '1:648546485642:web:cbd7b43259a27cd52ef473',
      measurementId: 'G-57V71EX8MY',
    },
    nnhcp: {
      apiKey: 'AIzaSyD7dKxH5wj3HgnICiIzuYZjnLvZIZtfA4U',
      authDomain: 'nn-hcp-stage.firebaseapp.com',
      projectId: 'nn-hcp-stage',
      storageBucket: 'nn-hcp-stage.appspot.com',
      messagingSenderId: '699862024755',
      appId: '1:699862024755:web:8f6d11485f5bba1d23d946',
      measurementId: 'G-ZJKWHHEH17',
    },
    nnNovoCare: {
      apiKey: 'AIzaSyCGwjItRJqddxyD4GbXAzYVSnNsaiJp78I',
      authDomain: 'nn-novocare-stage.firebaseapp.com',
      projectId: 'nn-novocare-stage',
      storageBucket: 'nn-novocare-stage.appspot.com',
      messagingSenderId: '521137210355',
      appId: '1:521137210355:web:8c9fc79949530d72258886',
      measurementId: 'G-2SWRRJ5J9P',
    },
    obesityCarePsp: {
      apiKey: 'AIzaSyD6cchlu8BP37ycABAu3WkAFvPsVq7G2bw',
      authDomain: 'nn-platform-stage.firebaseapp.com',
      projectId: 'nn-platform-stage',
      storageBucket: 'nn-platform-stage.appspot.com',
      messagingSenderId: '824830670374',
      appId: '1:824830670374:web:c81637da81fb29c812d8e7',
    },
    aiiiMed: {
      apiKey: 'AIzaSyCo7Xo2dkGTPb3BYa4key6SYTO-CeM8yr8',
      authDomain: 'aiii-med-stage.firebaseapp.com',
      projectId: 'aiii-med-stage',
      storageBucket: 'aiii-med-stage.appspot.com',
      messagingSenderId: '536610938563',
      appId: '1:536610938563:web:dc4507ca2bb11977e6cc76',
      measurementId: 'G-Q53Q9KNECH',
    },
    astrazeneca: {
      apiKey: 'AIzaSyBn_5vGmSQjpMUx2CAWOVUT2n5kye2b-Vk',
      authDomain: 'astrazeneca-hcp-stage.firebaseapp.com',
      projectId: 'astrazeneca-hcp-stage',
      storageBucket: 'astrazeneca-hcp-stage.appspot.com',
      messagingSenderId: '934059698633',
      appId: '1:934059698633:web:1d2fd90c2c3ea41d632018',
      measurementId: 'G-N2ZDXXRF9P',
    },
    zptth: {
      apiKey: 'AIzaSyDBlYFbiyJqvlYcQbyJ3fYjqWD9bS1j-O4',
      authDomain: 'zptth-hcp-stage.firebaseapp.com',
      projectId: 'zptth-hcp-stage',
      storageBucket: 'zptth-hcp-stage.appspot.com',
      messagingSenderId: '194638435727',
      appId: '1:194638435727:web:5a4f95eb3bae5c7fec27fa',
      measurementId: 'G-M9V2CKDK1Y',
    },
    drCare: {
      apiKey: 'AIzaSyAD9tn7CCmIBVwOfRAyPANu6gFBnLPWbRU',
      authDomain: 'aiii-drcare-stage.firebaseapp.com',
      projectId: 'aiii-drcare-stage',
      storageBucket: 'aiii-drcare-stage.appspot.com',
      messagingSenderId: '858619986767',
      appId: '1:858619986767:web:fab049c6df5a06ea65fb3a',
      measurementId: 'G-NH8S09YCMH',
    },
    gskline: {
      apiKey: "AIzaSyAOuYqs9e5E15_MhE1pVcfmEw-bGug_tOo",
      authDomain: "gsk-hcp-stage.firebaseapp.com",
      projectId: "gsk-hcp-stage",
      storageBucket: "gsk-hcp-stage.appspot.com",
      messagingSenderId: "243577273277",
      appId: "1:243577273277:web:46e40a22160294569ff0d4",
      measurementId: "G-L4SVDNQJ5Y"
    },
    elililyhcp: {
      apiKey: 'AIzaSyARdZYUnuIVwTQ8qe23CL10cwFNsansDbw',
      authDomain: 'elilily-hcp-stage.firebaseapp.com',
      projectId: 'elilily-hcp-stage',
      storageBucket: 'elilily-hcp-stage.appspot.com',
      messagingSenderId: '868743022020',
      appId: '1:868743022020:web:af7659942924e8758e5091',
      measurementId: 'G-JT5S5R8XZD',
    },
  },
  useOtherFirebaseProject: {
    sites: ['testAllan', 'nnhcp', 'nnNovoCare', 'obesityCarePsp', 'aiiiMed', 'astrazeneca', 'zptth', 'drCare', 'gskline', 'elililyhcp'],
  },
  cloudrun: {
    api: 'https://api-v2-5uvz3vc6ga-de.a.run.app',
    platformPush: 'https://platform-push-v1-5uvz3vc6ga-de.a.run.app',
    platformPushAudienceClient: 'https://platform-push-audience-client-5uvz3vc6ga-de.a.run.app',
    basicWebhook: 'https://prod-line-dialogflow-5uvz3vc6ga-de.a.run.app',
    lottery: [
      'https://api-lottery-01-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-02-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-03-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-04-5uvz3vc6ga-de.a.run.app',
      'https://api-lottery-05-5uvz3vc6ga-de.a.run.app',
    ],
    couponOnline: 'https://api-coupon-online-5uvz3vc6ga-de.a.run.app',
    recommendation: 'https://recommendation-5uvz3vc6ga-de.a.run.app',
    liveStream: 'https://api-live-stream-5uvz3vc6ga-de.a.run.app',
    scratch: 'https://aiii-api-lottery-scratch-5uvz3vc6ga-de.a.run.app',
    apiMemberForm: 'https://api-member-form-5uvz3vc6ga-de.a.run.app',
    med: 'https://aiii-api-med-5uvz3vc6ga-de.a.run.app',
    medAPIKey: 'AIzaSyD5Q1-FGHUIJKMNJHY6*IKHUJYGTFRD',
    gskApi: 'https://gsk-tw-hcp-api-med-5uvz3vc6ga-de.a.run.app',
    video: 'https://aiii-api-video-5uvz3vc6ga-de.a.run.app',
  },
  gke: {
    internalApi: 'https://stage-internal-api.aiii.ai',
    internalApiKey: '',
    liffUrl: 'https://stage-liff.aiii.ai',
  },
  vimeoWhileList: ['stage-console.aiii.ai', 'stage-liff.aiii.ai', 'aiii-liff-med-dev.web.app', 'gsk-tw-liff-med-dev.web.app', 'stage-gskline.aiii.ai'],
  liff: {
    medLiffId: 'testtt',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
