import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LiffService } from 'src/app/shared/liff.service';
import { LiffGuard } from './shared/liff.guard';
import { medChatAuthGuard } from './med-chat/med-chat-auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      // {
      //   path: 'med-sales',
      //   loadChildren: () => import('./med-sales/med-sales.module').then((m) => m.SalesModule),
      // },
      {
        path: 'med-chat',
        loadChildren: () => import('./med-chat/med-chat.module').then((m) => m.MedChatModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
      },
      // {
      //   path: 'hcp',
      //   loadChildren: () => import('./hcp/hcp.module').then((m) => m.HcpModule),
      // },
      // {
      //   path:'video',
      //   loadChildren: () => import('./video/video.module').then((m) => m.VideoModule),
      // },
      {
        path: '**',
        redirectTo: 'login',
      }
    ],
  },

  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [LiffService],
})
export class AppRoutingModule { }
