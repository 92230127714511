import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BigQueryLog } from '@aiii/aiii-types/shared/big-query/create-big-query-log';
import * as moment from 'moment-timezone';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  Firestore,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
@Injectable()
export class LiffService {
  public isInit = false;
  public liff: LIFF = liff;
  public profile: LineUserProfile = {
    userId: '',
    displayName: '',
    pictureUrl: '',
    statusMessage: '',
  };

  public initCount = 0;

  constructor(public http: HttpClient, private firestore: Firestore) {}

  async initLiff(liffId: string, site: string) {
    ++this.initCount;
    let step = '01';
    try {
      if (!this.isInit && this.initCount < 2) {
        console.log('***initLiff', this.isInit);
        this.isInit = true;
        await this.liff.init({
          liffId,
        });
        console.log('****isInit end', liffId);
      }

      step = '02';

      if (this.liff.isLoggedIn()) {
        step = '03';
        this.profile = await this.liff.getProfile();
        step = '04';
        const decodedIDToken = liff.getDecodedIDToken();
        sessionStorage.setItem('uid', this.profile.userId);
        if (decodedIDToken) {
          this.profile.email = decodedIDToken.email || '';
          this.profile.phone = decodedIDToken.phone_number || '';
          this.updateLineProfile(
            this.profile.userId,
            this.profile.displayName,
            this.profile.pictureUrl || '',
            this.profile.email || '',
            this.profile.phone || ''
          ).catch();
        }
        step = '05';
        this.liffOpenLog(liffId, site);
      }

      step = '06';

      if (this.isDevMode()) {
        const devLiffConfig = localStorage.getItem('dev-liff-config');
        console.log('dev liff cfg=', devLiffConfig);

        if (devLiffConfig) {
          this.profile = JSON.parse(localStorage.getItem('dev-liff-config') as string);
        }
      }

      step = '07';
    } catch (err: any) {
      console.error(err);
      let errorJSON = {};
      try {
        errorJSON = JSON.parse(JSON.stringify(err));
      } catch (err) {
        console.error(err);
      }

      await addDoc(collection(this.firestore, 'dev-log'), {
        liffId: liffId || 'noLiffId',
        message: err.message,
        userAgent: window.navigator.userAgent,
        createdAt: serverTimestamp(), // 使用 serverTimestamp
        errorJSON,
        href: window.location.href,
        step,
        initCount: this.initCount,
      });

      if (this.initCount < 2) {
        this.initCount++;
        this.initLiff(liffId, site);
      } else {
        throw new Error('此APP尚未啟用');
      }
    }
  }

  private async updateLineProfile(
    userId: string,
    displayName: string,
    pictureUrl: string,
    email: string,
    phone: string
  ): Promise<void> {
    if (email || phone) {
      try {
        const userDoc = doc(this.firestore, `/lineUsers/${userId}`);
        await setDoc(
          userDoc,
          {
            displayName: displayName || '',
            pictureUrl: pictureUrl || '',
            email: email || '',
            phone: phone || '',
          },
          { merge: true }
        );
      } catch (error) {
        console.error('Error updating LINE profile:', error);
      }
    }
  }

  /**
   * 判斷是否為本機開發模式
   */
  public isDevMode() {
    return window.location.host.includes('localhost:');
  }

  /**
   * 登入 LIFF 帳號
   * @param redirectUri 登入後跳轉的網址
   */
  public login(redirectUri = window.location.href) {
    localStorage.setItem('dev-liff-login', '1');
    if (!this.isDevMode()) {
      this.liff.login({
        redirectUri,
      });
    }
  }

  /**
   * 登出 LIFF 帳號
   */
  public logout() {
    localStorage.setItem('dev-liff-login', '');
    this.liff.logout();
  }

  /**
   * 判斷登入狀態
   */
  public isLoggedIn() {
    try {
      return (this.isDevMode() && !!localStorage.getItem('dev-liff-login')) || this.liff.isLoggedIn();
    } catch (err) {
      console.log('isLogin', err);
      return false;
    }
  }

  /**
   * 判斷環境 "ios" | "android" | "web"
   */
  public getOS() {
    try {
      return this.liff.getOS();
    } catch (err) {
      return '';
    }
  }

  /**
   * 判斷LIFF的版本
   */
  public getVersion() {
    try {
      return this.liff.getVersion();
    } catch (err) {
      return '';
    }
  }

  /**
   * 判斷LIFF的版本
   */
  public getLineVersion() {
    try {
      return this.liff.getLineVersion();
    } catch (err) {
      return '';
    }
  }

  /**
   * 判斷是否為原生LIFF的環境
   */
  public isInClient() {
    try {
      return this.liff.isInClient();
    } catch (err) {
      return false;
    }
  }

  /**
   * 取得 AccessToken
   */
  public getAccessToken() {
    try {
      return this.liff.getAccessToken();
    } catch (err) {
      return '';
    }
  }

  /**
   * 取得裝置語系
   */
  public getLanguage() {
    try {
      return this.liff.getLanguage();
    } catch (err) {
      return '';
    }
  }

  /**
   * 發送並紀錄 User Log
   * @param site 必填 站台名稱
   * @param userId 必填 使用者的 userId
   * @param action 必填 事件名稱;
   * @param label 選填 自訂標籤/Key值;
   * @param value 選填 key/標籤
   * @param payload 選填 key/標籤
   * @param category 選填 事件類型
   * @param event 選填 事件名稱
   */
  async log(
    site: BigQueryLog['site'],
    userId: BigQueryLog['userId'],
    action: BigQueryLog['action'],
    label?: BigQueryLog['label'],
    value?: BigQueryLog['value'],
    payload?: unknown,
    category?: BigQueryLog['category'],
    event?: BigQueryLog['event']
  ) {
    try {
      await this.http
        .post(`${environment.cloudrun.api}/log`, {
          site,
          userId,
          category: category || 'LineLiff',
          action,
          label,
          value,
          payload,
          event,
        })
        .toPromise();
    } catch (err) {
      console.error(JSON.stringify(err));
    }
  }

  async getClientIP(): Promise<string> {
    try {
      const result = (await this.http.get(`${environment.cloudrun.api}/get-client-ip`).toPromise()) as any;
      return result.IP || '';
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  async getServerTimestamp(timezone = 'Asia/Taipei'): Promise<Date> {
    const result = (await this.http.get(`${environment.cloudrun.api}/get-server-timestamp`).toPromise()) as any;
    return moment.tz(result.timestamp || '', timezone).toDate();
  }

  /**
   * 查詢站台是否存在
   * @param site 站台名稱
   */
  async querySiteExists(site: string): Promise<boolean> {
    try {
      const siteDoc = doc(this.firestore, `sites/${site}`);
      const docSnapshot = await getDoc(siteDoc);
      return docSnapshot.exists();
    } catch (error) {
      console.error('Error checking site existence:', error);
      return false;
    }
  }

  /**
   * 查詢該 site 站台的 botId 、Liff ID 及 公開資料
   * @param site 站台名稱
   */
  async querySiteProfile(site: string): Promise<{
    site: string;
    botId: string;
    pictureUrl: string;
    displayName: string;
    liff: LiffConfig;
    accountId: string;
    provider: string;
  }> {
    const result: any = await this.http.post(`${environment.cloudrun.api}/querySiteConfig`, { site }).toPromise();

    return result;
  }

  /**
   * LIFF 於開啟並初始化時，送出 LiffOpen Event
   * @param liffId
   * @param site
   */
  private liffOpenLog(liffId: string, site?: string) {
    if (this.profile.userId) {
      const url = new URL(window.location.href);
      let targetSite = '';
      try {
        targetSite = site || url.searchParams.get('site') || url.pathname.split('/')[2] || 'noSite';
      } catch (err) {
        console.error(err);
        targetSite = 'noSite';
      }
      this.log(
        targetSite,
        this.profile.userId,
        'liffOpen',
        liffId,
        window.navigator.userAgent,
        window.location.href || ''
      );
    }
  }

  async addLabels(site: string, userId: string, labels: string[], eventAction: string, eventLabel: string) {
    if (labels.length > 0) {
      const userLabels: any = {};
      for (const label of labels) {
        const labelPath = `labels.${label}`;
        userLabels[`${labelPath}.from`] = arrayUnion(site);
        userLabels[`${labelPath}.updateAt`] = new Date();
        userLabels[`${labelPath}.enable`] = true;
        userLabels[`${labelPath}.types`] = arrayUnion('liff');
      }
      try {
        const userDocRef = doc(this.firestore, `lineUsers/${userId}`);
        await updateDoc(userDocRef, userLabels);
      } catch (error) {
        console.error('Error updating labels:', error);
      }
    }
    this.log(site, userId, eventAction, eventLabel, labels.join(','));
  }

  /**
   * 若為手機則強制使用原生 LIFF 分享
   * @param messagea
   */
  async forcedLiffShare(site: string, message: any[]) {
    try {
      if (this.getOS() === 'android' || this.getOS() === 'ios') {
        // 若使用手機
        if (this.isInClient()) {
          // 已是原生 LIFF 環境
          await liff.shareTargetPicker(message);
        } else {
          // 手機的 LINE 瀏覽器 或 外部瀏覽器
          const siteProfile = await this.querySiteProfile(site);
          const encodeText = encodeURIComponent(JSON.stringify(message));
          const httpReferer = encodeURIComponent(window.location.href);
          const shareLiffUrl = `https://liff.line.me/${siteProfile.liff.shareTargetPickerLiffID}?site=${site}&messageEncode=${encodeText}`;
          window.location.href = shareLiffUrl;
        }
      } else {
        // 桌機 直接使用 SDK 分享
        if (this.isLoggedIn()) {
          await liff.shareTargetPicker(message);
        } else {
          this.login();
        }
      }
    } catch (error: any) {
      alert(error.message);
    }
  }
  async close() {
    await liff.closeWindow();
  }

  async isFollow(): Promise<boolean> {
    if (this.isDevMode()) {
      return true;
    }
    const getFriendshipResponse = await liff.getFriendship();
    return getFriendshipResponse.friendFlag ?? false;
  }
}
